.content-manager-add-button tr td {
  text-align: center;
}

.span-coordinate {
  text-overflow: ellipsis;
  max-width: 10ch;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width:680px) {
  .span-title {
    text-overflow: ellipsis;
    max-width: 20ch;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }
  .span-author {
    text-overflow: ellipsis;
    max-width: 10ch;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }
}