.neighbourhood-container {
    position: relative;
}

.neigbourhood-filter {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
    width: 100%;
}

.neigbourhood-filter a {
    width: 50px;
    display: inline-block;
    font-size: x-large;
    padding: 3px;
    cursor: pointer;
    text-align: center;
}

.hide-other-public .poi.other.public,
.hide-own-public .poi.own.public,
.hide-own-private .poi.own.private,
.hide-visited-skipped .poi.skipped,
.hide-visited-skipped .poi.visited {
    display: none;
}

.poi-filter-icon {
    background-color: black;
    color: #0E7DFF;
}

.poi-filter-icon a {
    color: black;
    cursor: none;
}



.neigbourhood-filter.hide-other-public a.poi.other.public,
.neigbourhood-filter.hide-own-public a.poi.own.public,
.neigbourhood-filter.hide-own-private a.poi.own.private,
.neigbourhood-filter.hide-visited-skipped a.poi.skipped,
.neigbourhood-filter.hide-visited-skipped a.poi.visited {
    background-color: darkgrey;
    display: inline-block;
}