body.login-page #root .container {
  background-color: initial !important;
  margin: 0;
  padding: 0 !important;
  max-width: 100vw;
}

.login-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 100px;
  height: 100vh;
  align-items: center;
}

body.login-page {
  background-image: url('../assets/background-maxi.jpeg');

  margin-right: 0;
  padding-right: 0;
}

.login-header {
  color: white;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;

}

.login-header h1 {
  font-size: 7vw;
  text-align: center;
}

.login-panel {
  background-color: white;

  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 2;

  padding: 20px
}

.login-panel.loading {
  pointer-events: none;
}

.login-panel>div {
  width: 100%;
}

.login-panel>div>div>iframe {
  margin-left: auto !important;
  margin-right: auto !important;
}

.login-error {
  background-color: #dc3545 !important;
  text-align: center;
  border-radius: 120px;
  color: white;
  display: block;
  width: 50% !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

.login-loading {
  text-align: center;
  color: black;
  margin-top: 10px;
}

.login-preHeader {
  font-size: 3vw;
  visibility: collapse;
}

@media (min-width:680px) {
  body.login-page {
    background-image: none;
  }

  .login-image {
    background-image: url('../assets/background-maxi.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    width: 50%;
  }

  .login-container {
    display: flex;
    flex-direction: row;
  }

  .login-page .login-image {

    width: 50%;
  }

  .login-page .login-action-container {

    width: 50%;
    color: black;
  }

  .login-header {
    color: black;
    margin-bottom: 10vh;
  }

  .login-page .login-header {
    width: 100%;
  }

  .login-page .login-panel {
    width: 100%;
  }

  .login-preHeader {
    display: block;
    visibility: block;
    margin: auto;
    width: 50%;
    text-align: center;
    visibility: visible;
  }
}