
.carousel .slide img {
    object-fit: cover;
}

.carousel .slide .legend.customized {
    bottom: 40px;
    left: 50%;
    border-radius: 10px;
    color: inherit;
    background-color: inherit;
    padding: 10px;
    font-size: 12px;
    text-align: initial;
    position: initial;
    bottom: auto;
    margin-left: auto;
    width: auto;
    line-height: 30px;
}


/* Click-Button in the gallery should be over the whole image */
[data-testid=grid-gallery-item_check-button], 
.ReactGridGallery_tile-icon-bar {
    width: 100% !important;
    height: 100% !important;
}