
.theme-2 header.app-header {
  background-color: white;
}
.theme-2 .btn.btn-primary {
  background-color: black;
  color: white;
  margin: 5px;
}

.theme-2  .btn.btn-secondary {
  background-color: black;
  color: white;
  margin: 5px;
}

body.theme-2  {
  
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: Roboto;
}

body.theme-2.default-page, .theme-2 .app-layout {
background-color:black;
}

.theme-2 #root .container {
  background-color: black;
  color:white;
}

.theme-2 .login-header h1, .theme-2 .login-header span {
  color:white;
}
.theme-2 .infoIcon {
  color: black;
}
.theme-2 header .app-header {
  background-color: white;
}

.theme-2 .poi.other.public {
  color:white;
}

.theme-2 .app-header .container{
  background-color: white !important;
}
.theme-2 .content-manager-poi-table thead {
  background-color:white; 
  color:black; 
}
.theme-2 .content-manager-nav {
  background-color:#F5F5F5;

}
.theme-2 .content-manager-nav .nav-item .nav-link  {
  color:grey;
}
.theme-2 .infoText {
  color:black;
}

.theme-2 .content-manager-nav .nav-item .active.nav-link  {
  color:black;
}

.theme-2 .pagination .page-item a {
  color:black; 
}
.theme-2 .pagination .page-item.active a {
  background-color:grey; 
  border-color: grey;
}
.theme-2 .content-manager {
  background-color: black;
  color:white; 
}
.theme-2 .content-manager .content-manager-poi-table  {
  background-color: white; 
  color: black;

}

.theme-2 .content-manager .content-manager-poi-table tbody, 

.theme-2 .content-manager .content-manager-poi-table tbody tr td
  {
  background-color: black; 
  color: white;
  border: white;

}
.theme-2 .poi-table-title, 
.theme-2 .poi-table-title h3 {
  background-color: white;
  color:black;
}

.theme-2 .poi-table-title h3  
{
  color:black !important;
}
.theme-2 .content-manager-poi-table thead {
  background-color:black;
  color:white;
}
.theme-2 .content-manager-poi-table  tr td {
  border:solid white;
}

.theme-2 .content-manager-poi-table tbody tr {  
  background-color:#ececec; 
  border: solid white;
}

.theme-2 .content-manager-add-button .btn{
  background-color: black;
  color: white;
}

.theme-2 .fa-location-pin.poi {
  color:white;
}

.theme-2 .modal {
  
  background-color: black !important;
  color:white; 
}

.theme-2 .modal-header,
.theme-2 .modal-body, 
.theme-2 .modal-footer {

  background-color: black;
  color:white; 
}


.theme-2 .profile-button-container .btn {
  color:white;
}

.theme-2 tr:nth-child(even) {
 
  background-color: grey; 
  color: black;
}

.theme-2 tr:nth-child(odd) {

  background-color: black; 
  color: white;
}

.theme-2 ul li, 
.theme-2 ul li a {
  background-color: black;
  color:white !important;
}