.lock-screen-overlay .react-pattern-lock__pattern-wrapper {
    background-color: black;
}

.lock-screen-overlay [role=button] {
    display: none;
}

.explore-poi-data .btn .fa-heart {
    color: white;
}



.exploring-button {
    width: 100%;
    background-color: black;
    color: white;
}

.exploring-stop {
    background-color: #FD0606 !important;
}

.exploring-hints {
    margin-top: 50px;
}

.exploring-hints .accordion-body {
    background-color: #e7f1ff9f;
}

.exploring-hints ul {}

.exploring-hints .introduction-link {
    position: absolute;
    color: #0e7dff;

}


.info-unlock-pattern {
    color: white;
    text-align: center;
}



[class*="styles_overlay-wrapper"]>[class*="styles_backdrop"] {
    background-color: black !important;
}

.lock-screen-overlay {
    background-color: black !important;
}

.lock-screen-overlay .react-pattern-lock__pattern-wrapper {
    margin: auto;
}

.lock-screen-overlay {
    padding: 0 !important;
    height: 100%;
    width: 100%;
    max-width: none !important;
}

/* Disables pull down refresh on lock screen */
html:has([class*="styles_overlay-open"]),
body:has([class*="styles_overlay-open"]) {
    overscroll-behavior: none;
}

.listeningIcon {
    font-size: 3em;
}

.explore-application-buttons {
    margin-top: 50px;
    margin-bottom: 30px;

}


.explore-application-buttons button {
 
}

.explore-poi-detail {
    margin-top: 5px;
}



.exploring-button {
    font-size: x-large;
}


.exploring-container-legend {
    position: relative;
    margin-bottom: 40px;
}

.exploring-container-legend {
    position: relative;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
    width: 100%;
    font-size: x-large;
}

.exploring-container-legend a {
    width: 50px;
    display: inline-block;
    font-size: x-large;
    padding: 3px;
    cursor: pointer;
    text-align: center;
}

.hide-other-public .poi.other.public,
.hide-own-public .poi.own.public,
.hide-own-private .poi.own.private,
.hide-visited-skipped .poi.skipped,
.hide-visited-skipped .poi.visited {
    display: none;
}

.poi-filter-icon {
    background-color: black;
    color: #0E7DFF;
}

.poi-filter-icon a {
    background-color: black;
    cursor: none;
}

.exploring-container-legend.hide-other-public a.poi.other.public,
.exploring-container-legend.hide-own-public a.poi.own.public,
.exploring-container-legend.hide-own-private a.poi.own.private,
.exploring-container-legend.hide-visited-skipped a.poi.skipped,
.exploring-container-legend.hide-visited-skipped a.poi.visited {
    background-color: darkgrey;
    display: inline-block;
}

.exploring-container-legend .circle-info-icon {
    margin-left: 5px;
}

.accordion-header button,
.accordion-header .circle-info-icon {
    font-size: large;
}

.explore-application-buttons button {
    float:left;
}
.explore-application-buttons p  .listeningIcon  {
    font-size: x-large;
}

.exploration-loading {
    text-align: center;
}