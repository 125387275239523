@import url('https://fonts.cdnfonts.com/css/roboto');


body, html {
  height: 100%;
}

h1{
  margin-bottom: 3%;
  font-family:"Comfortaa" ;
}

h1, h2, h3 {
  text-transform: uppercase;
  
  margin-top:2%;
}

.btn.btn-primary {
  background-color: black;
  color: white;
  margin: 5px;
}

.btn.btn-secondary {
  background-color: black;
  color: white;
  margin: 5px;
}

body {
  
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: Roboto;
  margin-right: 5px;
  padding-right: 5px;
}

body.default-page {
 /* background-image: url('assets/Hauptplatz-ausstellung-1.jpeg');
  background-repeat: repeat-y;
*/
}

#root .container {
  /*background-color: rgba(255, 255, 255, 0.8);*/
  background-color: white;
  border-radius: 5px;
  padding: 10px;
}
/*
      window.document.body.classList.add("font-size-" + currentUser.fontSize);
      window.document.body.classList.add("theme-" + currentUser.theme);
*/
/*
a {
  color: #0366d6;
}

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
*/



.poiIcon {
  font-size: x-large;
  margin-right: 5px;
}

.userCurrentPositionIcon {
  margin-right: 5px;
  color:#0E7DFF;
}

.homePositionMarkerIcon{
  margin-right: 5px;
  color:#19A037;
  font-size: x-large;
}

/* in settings the user icon is the home position */
#settingsForm .userCurrentPositionIcon{
  color:#19A037;
}

.infoText {

}

.info {
  background-color: lightgoldenrodyellow;
}

.infoIcon {
  font-size: x-large;
  color:black;
}
.homePositionMarkerInMap {
  font-size: xx-large;
}
.locationBadgeBackground {
  background-color: red;
}

.userCurrentPositionInMap {
  font-size: xx-large;
}

.margin-top-class {
  margin-top: 5px;
}

.poi-container {
  white-space: nowrap;
}


.poi, 
.poi.day-mode.other.public {
  color: black;
}
.poi.night-mode, 
.poi.night-mode.other.public {
  color:white;
}


.poi.own, 
.poi.day-mode.own, 
.poi.night-mode.own {
  color: #E0B312 !important;
}

.poi.visited, 
.poi.skipped, 
.poi.night-mode.visited, 
.poi.night-mode.skipped, 
.poi.day-mode.visited, 
.poi.day-mode.skipped,
.poi-container .poi.visited, 
.poi-container .poi.skipped, 
.poi-container .poi.day-mode.visited, 
.poi-container .poi.day-mode.skipped
.poi-container .poi.night-mode.visited, 
.poi-container .poi.night-mode.skipped
 {
  color:grey !important;
}


.searchRangeMax span {
  float: right;
}


.fa-heart, .color-red, .color-heart {
  color: #FD0606;
}

.fa-certificate {
  color:gold;
}
.color-badge {
  color:#AEB115;
}

.newsletter-dropdown {
  width: 100px;
  display: inline;
  margin-left: 5px;
  margin-right: 5px;;
  margin-top:10px;
}

.newsletter-inactivity-text
{
  width:50px; 
  display: inline;
  margin-left: 5px;
  margin-right: 5px;;
}
.content-manager-poi-table thead {
  background-color:black; 
  color:white; 
}

.content-manager-nav {
  background-color:#F5F5F5;

}
.content-manager-nav .nav-item .nav-link  {
  color:grey;
}

.content-manager-nav .nav-item .active.nav-link  {
  color:black;
}

.pagination .page-item a {
  color:black; 
}
.pagination .page-item.active a {
  background-color:grey; 
  border-color: grey;
}
.poi-table-title {
  background-color: black;
  margin-bottom:0px; 
  margin-left:2px;
  margin-right:2px;
}

.poi-table-title h3 
{
  text-align: center;
  color: white !important;
  padding:10px;
  margin: 0px;
}
.content-manager-poi-table  tr td {
  border:solid white;
}
.content-manager-poi-table tbody tr {
  
background-color:#ececec; 
border: solid white;

}

.content-manager-add-button {
  float:right;
  margin-right: 5em;
  margin-bottom:5em;
  clear:both;
}
.clearer {
  clear:both;
}


.exploring-hints .accordion, .exploring-hints .accordion-item, .accordion-header , 
.accordion-button{
  background-color: lightgoldenrodyellow;
  margin-top:15px;
}

.accordion-button, 
.accordion-header, 
.accordion-body, 
.accordion-body a {
  color: black !important;
  background-color: lightgoldenrodyellow !important;
}

.accordion-collapse.collapse.show {
  color: darkslategrey;
}

.profile-header {
  margin-bottom: 3em;
}

.profile-page h1, h2, h3 {

  margin-bottom:1em;
}

.poi-table-author-image {
  border-radius: 50%; 
  width: 38px;
  margin-right: 5px;
}

.circle-info-icon {
  color: white; 
  font-size: x-large !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.infoText .circle-info-icon {
  font-size: xx-large;
}

.app-layout .control-prev, 
.app-layout .control-next, 
.modal-dialog .control-prev, 
.modal-dialog .control-next
 {
    width:100px !important;
    background-color:lightgrey;
}


.carousel.carousel-slider .control-arrow 
  {
  background-color:rbga(0,0,0,0.8);
}
.app-layout .carousel-status, 
.modal-dialog .carousel-status  {
    font-size: larger;
}


.clearer {
  clear:both;
}

.text-left {
  text-align: left;
}


.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.point-of-interest-modal-picture-container {
  background-color:rgba(0,0,0,0.8); 
}

.point-of-interest-modal-picture-container  .point-of-interest-modal-picture {
  max-height: 450px;
}

tr:nth-child(even) {
  background-color: white;
}
tr:nth-child(odd) {
  background-color: rgba(0,0,0,0.1); ;
}
table {
  border-spacing: 0px;
}

.preline-enabled {
  /*always transforms \n to new line*/
  white-space: pre-line;
}

.a-to-button, 
.a-to-button:hover {
  background-color:black; 
  color:white;
  border: 0;
}