.point-of-interest-modal-picture-container {
    position: relative;
    max-height: 500px;
}

.point-of-interest-modal-picture-meta-data {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    color:white;
}

.carousel .slide .point-of-interest-modal-picture-meta-data .legend.customized {
    border-radius: 0;
    margin-bottom: 0;
}

.point-of-interest-modal-picture {
    max-width: 500px;
}
.point-of-interest-modal-picture-meta-data .legend.customized p {
    font-size: 20px;
}