.profile-image-container {
    position: relative;
    display: inline-block;
}

.profile-image-container .profile-badge {
    position: absolute;
    font-size: 70px;
    bottom: -10px;
    left: 30px;
}

.profile-button-container {
    text-align: right;

}

.profile-button-container button {
    font-size: xx-large;
    font-weight:100;
}

.profile-page h3 {
    margin-top:50px;
}

.profile-reset-all-pois {
    margin: 0px;
}