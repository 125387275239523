.navbar-brand {
  font-size: 2.0rem;
  font-family: "Comfortaa";
}
a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}
nav {
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 36px;
}
html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

/* Hide navigation text if the full navbar is shown */
@media (min-width: 576px) {
  .navbar-nav .nav-item a span {
    display: none;
  }
}

@media (max-width: 576px) {
  .nav-tooltip-auto {
    display: none;
  }
}