.upload-image-container {
    position: relative;
    text-align: center;
    background-color: black;
}

.upload-image-container .bug-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: xx-large;
    color: white;
    z-index: 1;
}

.upload-image-container .upload-image-attributes {
    position: absolute;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    width: 100%;
}

.quickadd-submit-button {
    margin-left: -5px;
}

.quickadd-buttons-annotate {
    margin-left: -5px;
}

.btn-quick-add {
    padding-left: 32px;
}

.btn-quick-add-icon {
    position: relative;
    left: 30px;
    top: 2px;
    color: white;
    z-index: 2;
}

.upload-images-container .row {
    margin-bottom: 5px;
}

.poi-position-marker-icon {
    height: 32px;
    width: 20px;
    margin-right: 5px;
}

.input-group .btn.btn-secondary {
    margin: 0;
}

.upload-image-container img {
    max-width: 150px;
}

@media (max-width: 576px) {
    .upload-image-container img {
        max-width: 300px;
    }
}

.upload-image-attributes p {
    font-size: smaller;
}


.quick-add-owner-data {
    background-color:rgba(0, 0, 0, 0.1); 
    margin-top:5px; 
    margin-bottom:5px; 
}

