

body.font-size-2, .font-size-2 div, .font-size-2 label, .font-size-2 div {
  font-size: xx-large;
}

.font-size-2 select {
  font-size: large;
}
.font-size-2 h1,h2
{
  font-size: xx-large;
}
.font-size-2 h3,button, a
.font-size-2 .poiIcon {
  font-size: larger;
}

.font-size-2 .homePositionMarkerIcon{
  font-size: xx-large;
}


.font-size-2 .homePositionMarkerInMap {
  font-size: xx-large;
}

.font-size-2 .userCurrentPositionInMap {
  font-size: xx-large;
}
.font-size-2 .exploring-button {
  font-size: xx-large;
}

.font-size-2 .content-manager-add-button .btn {
  font-size: xx-large;
}

.font-size-2 .content-manager-poi-table .page-item .a {
  font-size: xx-large;
}

.font-size-2 .accordion-header button, 
.font-size-2 .accordion-header .circle-info-icon {
    font-size: xx-large;
}

.font-size-2 .btn {
  font-size: xx-large;
}
