

body.font-size-0, .font-size-0 div, .font-size-0 label, .font-size-0 div{
  font-size: small;
}

.font-size-0 select {
  font-size: smaller;
}

.font-size-0 h1,.font-size-0 h2, .font-size-0 a
{
  font-size: small;
}
.font-size-0 h3, .font-size-0 button{
  font-size:smaller;

}
.font-size-0 .poiIcon {
  font-size: small;

}

.font-size-0 .homePositionMarkerIcon{
  font-size: small;
}


.font-size-0 .homePositionMarkerInMap {
  font-size: small;
}

.font-size-0 .userCurrentPositionInMap {
  font-size: small;
}


.font-size-0 .accordion-header button, 
.font-size-0 .accordion-header .circle-info-icon {
    font-size: small;
}

